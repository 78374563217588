import qs from 'qs';
import classNames from 'classnames/bind';
import { useRouter } from 'next/router';

import Device from '@/components/device/Device';
import Disclosure from '@/components/containers/disclosure/Disclosure';
import FestivalTiles from '@/components/festival/tiles/FestivalTiles';
import Icon, { IconType, IconSize } from '@/components/icon/Icon';
import SmartImage from '@/components/image/SmartImage';
import useDevice from '@/hooks/useDevice';

import styles from './styles.module.scss';
import { defaultUtmParams } from '@/utils/constants';

const cx = classNames.bind(styles);

const DownloadBanner = () => {
    const router = useRouter();

    const { isMobile, isTabletPortrait, calculating } = useDevice();

    const getGooglePlayUrl = () => {
        let query: Record<string, any> = router?.query;

        if (!Object.values(query).length) {
            query = {
                ...defaultUtmParams,
                ...{
                    utm_content: 'Get it on Google Play',
                    utm_term: 'download page',
                },
            };
        }

        return `https://play.google.com/store/apps/details?id=com.festgps&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1&${qs.stringify(query)}`;
    };

    const getAppStoreUrl = () => {
        const query: Record<string, any> = router?.query;
        const url =
            'https://apps.apple.com/app/apple-store/id6449910199?pt=121002828&ct=festgps_web_download&mt=8';

        if (
            query?.utm_campaign?.toLowerCase().replaceAll(' ', '') ===
            'usergrowth'
        ) {
            return 'https://apps.apple.com/app/apple-store/id6449910199?pt=121002828&ct=user%20growth&mt=8';
        }

        return url;
    };

    return (
        <section
            className={cx('download-banner')}
            role='complementary'
            aria-labelledby='get-the-app-title'>
            <div className={cx('__content', 'page__gutter')}>
                <div className={cx('__left-col')}>
                    <div className={cx('__masthead')}>
                        <h3 id='get-the-app-title' className={cx('__header')}>
                            Get the app
                        </h3>
                        <p className={cx('__text')}>
                            Browse and discover music festivals from around the
                            globe for free. FestGPS caters to you more and more
                            with every use.
                        </p>
                        <div className={cx('__store_buttons')}>
                            <div className={cx('__container')}>
                                <a
                                    className={cx('__button')}
                                    href={getGooglePlayUrl()}
                                    target='_blank'
                                    rel='noopener'
                                    aria-label='Get it on Google Play'>
                                    <SmartImage
                                        className={cx('__google')}
                                        alt='Get it on Google Play'
                                        src='/images/global/google-play-badge.png'
                                        width={162}
                                        height={48}
                                    />
                                </a>
                            </div>
                            <div className={cx('__container')}>
                                <a
                                    className={cx('__button')}
                                    href={getAppStoreUrl()}
                                    target='_blank'
                                    rel='noopener'
                                    aria-label='Download on the App Store'>
                                    <SmartImage
                                        className={cx('__apple')}
                                        alt='Download on the App Store'
                                        src='/images/global/apple-badge.svg'
                                        width={144}
                                        height={48}
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className={cx('features')}>
                        {(isMobile || isTabletPortrait) && !calculating ? (
                            <div className={cx('__disclosures')}>
                                <Disclosure
                                    icon={IconType.Search}
                                    heading='Discover Festivals'>
                                    <p className={cx('__body')}>
                                        Connect with streaming services or
                                        follow artists and get recommendations
                                        catered to your tastes.
                                    </p>
                                </Disclosure>
                                <Disclosure
                                    icon={IconType.CompareOutline}
                                    heading='Compare your favorites'>
                                    <p className={cx('__body')}>
                                        Stash your favorite artists and
                                        festivals in one place, instantly
                                        ranking festivals and comparing lineups.
                                    </p>
                                </Disclosure>
                                <Disclosure
                                    icon={IconType.Bell}
                                    heading='Personalized notifications'>
                                    <p className={cx('__body')}>
                                        Get notified when your favorites are
                                        playing together and other relevant
                                        news.
                                    </p>
                                </Disclosure>
                            </div>
                        ) : (
                            <>
                                <div className={cx('__feature')}>
                                    <div className={cx('__icon')}>
                                        <Icon
                                            name={IconType.Search}
                                            size={IconSize.Small}
                                        />
                                    </div>
                                    <h4 className={cx('__title')}>
                                        Discover Festivals
                                    </h4>
                                    <p className={cx('__body')}>
                                        Connect with streaming services or
                                        follow artists and get recommendations
                                        catered to your tastes.
                                    </p>
                                </div>
                                <div className={cx('__feature')}>
                                    <div className={cx('__icon')}>
                                        <Icon
                                            name={IconType.CompareOutline}
                                            size={IconSize.Small}
                                        />
                                    </div>
                                    <h4 className={cx('__title')}>
                                        Compare your favorites
                                    </h4>
                                    <p className={cx('__body')}>
                                        Stash your favorite artists and
                                        festivals in one place, instantly
                                        ranking festivals and comparing lineups.
                                    </p>
                                </div>
                                <div className={cx('__feature')}>
                                    <div className={cx('__icon')}>
                                        <Icon
                                            name={IconType.Bell}
                                            size={IconSize.Small}
                                        />
                                    </div>
                                    <h4 className={cx('__title')}>
                                        Personalized notifications
                                    </h4>
                                    <p className={cx('__body')}>
                                        Get notified when your favorites are
                                        playing together and other relevant
                                        news.
                                    </p>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className={cx('__right-col')}>
                    <Device size='sm' hasGradient={false} />
                </div>
            </div>
            <div className={cx('__gradient')}></div>
            {!isMobile && !calculating ? (
                <div className={cx('__tiles')}>
                    <FestivalTiles interactive={false} hideMeta />
                </div>
            ) : null}
        </section>
    );
};

export default DownloadBanner;
